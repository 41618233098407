export const shippingDataFixture = (options: shippingOptions = {}) => {
  if (options.empty) {
    return {
      tenantClassification: options.tenantClassification || 'consumer',
      supportMultiShipping: options.supportMultiShipping,
      id: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      fullState: '',
      zipCode: '',
      countryCode: options.countryCode || 'US',
      firstName: '',
      lastName: '',
      fullName: '',
      company: '',
      phoneNumber1: '',
      optedIn: false,
      isDefaultAddress: false,
      allowPoBox: false
    }
  }
  return {
    tenantClassification: options.tenantClassification || 'consumer',
    supportMultiShipping: options.supportMultiShipping,
    id: options.id || '123',
    street1: options.street1 || '16399 W Bernardo Dr',
    street2: options.street2 || '',
    city: options.city || 'San Diego',
    state: options.state || 'CA',
    fullState: options.fullState || 'California',
    zipCode: options.zipCode || '92127',
    countryCode: options.countryCode || 'US',
    firstName: options.firstName || 'John',
    lastName: options.lastName || 'Doe',
    fullName: options.fullName || 'John Doe',
    company: options.company || 'HP Inc.',
    phoneNumber1: options.phoneNumber1 || '1234567891',
    optedIn: options.optedIn || false,
    isDefaultAddress: options.isDefaultAddress || false,
    allowPoBox: options.allowPoBox || true
  }
}

type shippingOptions = {
  tenantClassification?: string
  supportMultiShipping?: boolean
  id?: string
  street1?: string
  street2?: string
  city?: string
  state?: string
  fullState?: string
  zipCode?: string
  countryCode?: string
  firstName?: string
  lastName?: string
  fullName?: string
  company?: string
  phoneNumber1?: string
  optedIn?: boolean
  isDefaultAddress?: boolean
  allowPoBox?: boolean
  empty?: boolean
}

export const errorResponse = (options: errorOptions = {}) => {
  return {
    response: {
      data: {
        error: {
          code: options.code || 'suggested_address_found',
          suggestedAddress: options.suggestedAddress || {}
        }
      },
      status: options.status || 400
    }
  }
}

type errorOptions = {
  status?: number
  code?: string
  suggestedAddress?: shippingOptions
}
