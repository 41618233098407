// import { errorResponse } from './../../fixtures/shippingData'
import { IShippingService } from '../../lib/shippingService'
import { AxiosResponse } from 'axios'
import { shippingDataFixture } from '../../fixtures/shippingData'

export class MockShippingService implements IShippingService {
  defaultDelay: number
  updateAddressResponses: { (id: string): Promise<unknown> }[]

  constructor(defaultDelay = 2000) {
    this.defaultDelay = defaultDelay
    this.updateAddressResponses = [
      // Uncomment to simulate update address error
      // () =>
      //   Promise.reject(
      //     errorResponse({
      //       code: 'unsupported_address_for_instant_paper',
      //       suggestedAddress: shippingDataFixture()
      //     })
      //   ),
      (id) =>
        this.resolve({
          data: shippingDataFixture({ id }),
          status: 200,
          statusText: 'ok',
          headers: {},
          config: {}
        })
    ]
  }

  resolve(
    result: AxiosResponse,
    delay: number = this.defaultDelay
  ): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(result), delay)
    })
  }

  getAddresses(): Promise<AxiosResponse> {
    return this.resolve({
      data: [
        shippingDataFixture({ id: '34567', isDefaultAddress: true }),
        shippingDataFixture({ id: '12345', isDefaultAddress: false })
      ],
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
    // return Promise.reject(
    //   errorResponse({
    //     code: 'error?'
    //   })
    // )
  }

  getAddress({ id }: { id: string }): Promise<AxiosResponse> {
    return this.resolve({
      data: shippingDataFixture({ id }),
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getAddressByCloudId({
    cloudId
  }: {
    cloudId: string
  }): Promise<AxiosResponse> {
    return this.resolve({
      data: shippingDataFixture({ id: '34567' }),
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  updateAddress({ id }: { id: string }): Promise<AxiosResponse> {
    if (this.updateAddressResponses.length > 1) {
      return this.updateAddressResponses.shift().apply(id)
    }
    return this.updateAddressResponses[0].apply(id)
  }

  saveAddress(): Promise<AxiosResponse> {
    return this.resolve({
      data: shippingDataFixture(),
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getSettings(): Promise<AxiosResponse> {
    return this.resolve({
      data: {
        showNonEditableBilling: true,
        enableCanaryIslands: true,
        showShippingAccountType: true,
        showShippingTaxId: true,
        showPrepaid: true,
        showReusableBillingForm: true,
        enableRebrandedHome: true,
        enableRebranding: true,
        criticalScopesModalTimeout: 0,
        enableSessionExpiredModal: true,
        enableStreetCharacterLimitErrors: true,
        enableSmbPoBoxExclusion: true,
        enableTextMessageOptin: true,
        enableNonWarrantyReplacementLink: true,
        newPaymentSwitcherStrings: true,
        enablePgsHostedFormV2: true,
        useStratusV2Template: true,
        merchantName: '7C4BD1C3115B2E49462D9BHPINSINKUS',
        twoCheckoutEnrollmentProductId: 'f9ad6d87-295f-41d4-bf90-e6b0fa1ff670',
        twoCheckoutTestMode: true,
        twoCheckoutMerchantId: 'HPINCBWX',
        systemTenantId: 'c1a46987-cbd3-45db-b94a-a6544116fc2d'
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  associateAddressWithPrinter({ id }: { id: string }): Promise<AxiosResponse> {
    return Promise.resolve({
      data: shippingDataFixture({ id }),
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }
}
