import {
  DispatchFunction,
  ShippingFormAction,
  ShippingFormDispatchHandler,
  ShippingFormState
} from '../types'
import { FormErrorCodes } from '../constants/shippingForm'
import { ShippingFormActionType } from '../constants/shippingFormAction'
import {
  handleSaveOrUpdateShippingDataAction,
  saveFormErrorCodeAction
} from '.'

const setShippingDataToSuggestedAddressAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.SET_SHIPPING_DATA_TO_SUGGESTED_ADDRESS
})

const closeSuggestedAddressModalAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.CLOSE_SUGGESTED_ADDRESS_MODAL
})

const handleShowAddressDifferencesAction =
  (): ShippingFormDispatchHandler =>
  async (dispatch: DispatchFunction, getState: () => ShippingFormState) => {
    const differences = new Set('')
    const shippingData = getState().shippingData
    const suggestedAddress = getState().suggestedAddress

    Object.keys(suggestedAddress).forEach((field) => {
      if (typeof suggestedAddress[field] === 'string') {
        const shippingField = shippingData[field]
        const suggestedField = suggestedAddress[field]

        if (shippingField && suggestedField) {
          if (shippingField.toUpperCase() !== suggestedField.toUpperCase()) {
            differences.add(field)
          }
        }
      }
    })

    await dispatch(
      saveFormErrorCodeAction(FormErrorCodes.ADDRESS_NOT_FOUND, differences)
    )
  }

export const handleShipToAddressAction =
  (): ShippingFormDispatchHandler =>
  async (dispatch: DispatchFunction, getState: () => ShippingFormState) => {
    const suggestedAddress = getState().suggestedAddress
    const selectedAddress = getState().selectedAddress

    if (suggestedAddress === selectedAddress) {
      await dispatch(setShippingDataToSuggestedAddressAction())
      await dispatch(handleSaveOrUpdateShippingDataAction())
    } else {
      await dispatch(handleShowAddressDifferencesAction())
    }

    await dispatch(closeSuggestedAddressModalAction())
  }
