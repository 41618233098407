function hideCity(country) {
  return ['SG', 'HK'].includes(country)
}

function hideZip(country) {
  return ['HK'].includes(country)
}

const addressHelpers = {
  hideCity,
  hideZip
}

export default addressHelpers
