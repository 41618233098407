import { useGetText } from './useGetText'
import { SelectOption } from '@veneer/core/dist/scripts/select'

export const useStateOptions = (): SelectOption[] => {
  const getText = useGetText()

  return JSON.parse(getText('state_dropdown.state_options')).map((option) => {
    const [value, label] = Object.entries(option)[0]
    return { value, label }
  })
}
