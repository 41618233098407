import { JarvisAuthProvider } from '@jarvis/web-http'
import { JarvisWebHttpClient } from '@jarvis/web-http/dist/jarvis-web-http-client'
import { BaseURLProvider } from '@jarvis/web-stratus-client'
import { AxiosResponse } from 'axios'
import 'regenerator-runtime/runtime'

export interface IShippingService {
  saveAddress({
    data,
    params
  }: {
    data: Record<string, unknown>
    params: Record<string, unknown>
  }): Promise<AxiosResponse>
  updateAddress({
    id,
    data,
    params
  }: {
    id: string
    data: Record<string, unknown>
    params: Record<string, unknown>
  }): Promise<AxiosResponse>
  getAddresses(): Promise<AxiosResponse>
  getAddress({ id }: { id: string }): Promise<AxiosResponse>
  getAddressByCloudId({ cloudId }: { cloudId: string }): Promise<AxiosResponse>
  getSettings(): Promise<AxiosResponse>
  associateAddressWithPrinter({
    id,
    cloud_id
  }: {
    id: string
    cloud_id: string
  }): Promise<AxiosResponse>
}

export class ShippingService implements IShippingService {
  httpClient: JarvisWebHttpClient
  xCorrelationId: string
  apiName = 'comfe'
  apiVersion = '1'
  addressesEndpoint = '/shipping/addresses'
  settingsEndpoint = '/settings'

  constructor(
    baseURLProvider: BaseURLProvider,
    authProvider: JarvisAuthProvider,
    xCorrelationId: string
  ) {
    this.httpClient = new JarvisWebHttpClient({
      defaultBaseURL: async () =>
        baseURLProvider(this.apiName, this.apiVersion),
      defaultAuthProvider: authProvider
    })
    this.xCorrelationId = xCorrelationId
  }

  async getAddresses(): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.addressesEndpoint}`
    })
  }

  async getAddress({ id }: { id: string }): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.addressesEndpoint}/${id}`
    })
  }

  async getAddressByCloudId({
    cloudId
  }: {
    cloudId: string
  }): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.addressesEndpoint}/devices/${cloudId}`
    })
  }

  async updateAddress({
    id,
    data,
    params
  }: {
    id: string
    data: Record<string, unknown>
    params: Record<string, unknown>
  }): Promise<AxiosResponse> {
    return this.httpClient.put({
      ...this.defaultHeaders(),
      url: `${this.addressesEndpoint}/${id}`,
      data: { ...data, ...params }
    })
  }

  async saveAddress({
    data,
    params
  }: {
    data: Record<string, unknown>
    params: Record<string, unknown>
  }): Promise<AxiosResponse> {
    return this.httpClient.post({
      ...this.defaultHeaders(),
      url: `${this.addressesEndpoint}`,
      data: { ...data, ...params }
    })
  }

  async getSettings(): Promise<AxiosResponse> {
    return this.httpClient.get({
      ...this.defaultHeaders(),
      url: `${this.settingsEndpoint}`
    })
  }

  async associateAddressWithPrinter({
    id,
    cloud_id
  }: {
    id: string
    cloud_id: string
  }): Promise<AxiosResponse> {
    return this.httpClient.put({
      ...this.defaultHeaders(),
      url: `${this.addressesEndpoint}/${id}/devices/${cloud_id}`
    })
  }

  private

  defaultHeaders() {
    if (this.xCorrelationId) {
      return {
        headers: {
          'X-Correlation-ID': this.xCorrelationId
        }
      }
    }
    return {}
  }
}
