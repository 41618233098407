import {
  AutofillShippingFormData,
  DispatchFunction,
  ShippingFormAction,
  ShippingFormData,
  ShippingFormDispatchHandler,
  ShippingFormState
} from '../types'
import { ShippingFormActionType } from '../constants/shippingFormAction'
import { setFetchErrorAction, setAddressCountAction } from '.'

export const fetchShippingDataAction = (): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SHIPPING_DATA
})

export const fetchShippingDataSuccessAction = (
  data: ShippingFormData
): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SHIPPING_DATA_SUCCESS,
  data
})

export const fetchShippingDataFailedAction = (
  error: Record<string, unknown>
): ShippingFormAction => ({
  type: ShippingFormActionType.FETCH_SHIPPING_DATA_FAILED,
  error
})

export const handleFetchShippingAddressAction =
  (): ShippingFormDispatchHandler =>
  async (dispatch: DispatchFunction, getState: () => ShippingFormState) => {
    try {
      await dispatch(fetchShippingDataAction())
      const { shippingService, addressId, cloudId, shippingFromAutofill } =
        getState()

      const dispatchShippingAddress = async (address: ShippingFormData) => {
        let newAddress: AutofillShippingFormData
        if (shippingFromAutofill) {
          newAddress = {
            street1: shippingFromAutofill.Address,
            city: shippingFromAutofill.City,
            zipCode: shippingFromAutofill.ZipCode,
            state: shippingFromAutofill.State,
            countryCode: shippingFromAutofill.Country
          }
        }
        for (const key in newAddress) {
          if (Object.prototype.hasOwnProperty.call(address, key))
            address[key] = newAddress[key]
        }
        await dispatch(fetchShippingDataSuccessAction(address))
      }

      if (cloudId) {
        const { data } = await shippingService.getAddressByCloudId({ cloudId })
        dispatchShippingAddress(data)
      } else {
        const { data } = await shippingService.getAddresses()
        await dispatch(setAddressCountAction(data.length))

        if (!addressId || !data[0].supportMultiShipping) {
          dispatchShippingAddress(data[0])
        } else {
          const address = data.find((address) => address.id === addressId)
          if (!address) {
            throw Error('No matching address found')
          } else {
            dispatchShippingAddress(address)
          }
        }
      }
    } catch (error) {
      await dispatch(fetchShippingDataFailedAction(error))
      await dispatch(setFetchErrorAction(handleFetchShippingAddressAction()))
    }
  }
